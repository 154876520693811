<template>

  <nabvar></nabvar>
  
  <div class="col">

 


 
  <HelloWorld />
  <Footer></Footer>
</div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import Nabvar from './components/Nabvar.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    HelloWorld,
    Nabvar,
    Footer
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

}
</style>
