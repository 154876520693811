<template>
  <div class="hello">
    <!DOCTYPE html>
<html>
<head>
  <title>Imágenes de fondo para elementos específicos</title>
  <link rel="stylesheet" href="styles.css">
</head>
<body>
  <div class="background-image-1">

    <h1 class=" italic text-4xl relative top-48 text-amber-100 drop-shadow-lg  lg:text-6xl  lg:absolute  lg:left-2/3 lg:top-40 ">NUESTROS</h1>
    <h1 class="italic text-5xl relative top-52 text-amber-100 font-bold drop-shadow-2xl lg:text-7xl   lg:absolute  lg:left-2/3 lg:top-56 ">EJECUTIVOS</h1>
    <h1 class="italic text-5xl relative top-56 text-amber-100 font-bold drop-shadow-2xl lg:text-7xl lg:absolute  lg:left-2/3 lg:top-72 ">COMERCIALES</h1>



<!-- medicorganic -->

<a href="https://medicorganics.com/">
<button type="button" class=" absolute left-24 top-3/4    2xl:absolute 2xl:w-40 2xl:h-20 2xl:left-1/2  2xl:top-1/2 text-lime-900 bg-amber-100 hover:bg-amber-200 focus:outline-none focus:ring-4 focus:ring-amber-200 font-medium rounded-full text-sm px-5 py-2.5 text-center mb-2 dark:focus:ring-amber-200 flex items-center space-x-2">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
    <path d="M2.25 2.25a.75.75 0 000 1.5h1.386c.17 0 .318.114.362.278l2.558 9.592a3.752 3.752 0 00-2.806 3.63c0 .414.336.75.75.75h15.75a.75.75 0 000-1.5H5.378A2.25 2.25 0 017.5 15h11.218a.75.75 0 00.674-.421 60.358 60.358 0 002.96-7.228.75.75 0 00-.525-.965A60.864 60.864 0 005.68 4.509l-.232-.867A1.875 1.875 0 003.636 2.25H2.25zM3.75 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM16.5 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z" />
  </svg>
  <span class="text-lg font-bold uppercase">VISITANOS</span>
</button>
</a>



<!-- instagram -->
<a href="https://www.instagram.com/wehe4l_cbd/">
    <button type="button" class="w-10 h-10 absolute top-2/3  lg:w-20 lg:h-20 lg:absolute   lg:right-1/4 lg:top-1/2  text-white drop-shadow-xl bg-amber-100 hover:bg-amber-200 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-amber-200 dark:hover:bg-amber-200 dark:focus:ring-amber-200">
      <svg class="h-16 w-16 text-lime-900"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="4" y="4" width="16" height="16" rx="4" />  <circle cx="12" cy="12" r="3" />  <line x1="16.5" y1="7.5" x2="16.5" y2="7.501" /></svg>

  </button> 
</a>



  <!-- facebook -->
  <a href="https://www.facebook.com/people/We-He4l-CBD/100091501405538/">
    <button type="button" class=" absolute w-10 h-10 top-2/3 right-48   lg:w-20 lg:h-20 lg:absolute  lg:right-82 lg:top-1/2 text-white drop-shadow-xl bg-amber-100 hover:bg-amber-200 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-amber-200 dark:hover:bg-amber-200 dark:focus:ring-amber-200">
      <svg class="h-20 w-40 text-lime-900"   width="28" height="34" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" /></svg>

  </button>
</a>


  </div>
  <div class="background-image-2">

  <!-- <div class="relative">
        <img class="  absolute   top-80  h-96 w-96 " src="../assets/img/derecha.png" alt="image description">
        <img class=" absolute right-0  top-80 h-96 w-96 max-w-lg ml-auto" src="../assets/img/izquierda.png" alt="image description"> 
  </div> -->


  <div class="grid lg:grid-cols-2 lg:gap-4 relative top-12 ">
  <!-- Primera columna -->
  <div>
    <img class="w-full h-auto rounded-lg" src="../assets/img/viviana-text.png" alt="Imagen 1">
    <img class="w-full h-auto rounded-lg" src="../assets/img/kevin-text.png" alt="Imagen 2">
    <img class="w-full h-auto rounded-lg" src="../assets/img/david-text.png" alt="Imagen 3">
  </div>

  <!-- Segunda columna -->
  <div>
    <img class="w-full h-auto rounded-lg" src="../assets/img/hector-text.png" alt="Imagen 4">
    <img class="w-full h-auto rounded-lg" src="../assets/img/marigon.png" alt="Imagen 5">
    <img class="w-full h-auto rounded-lg" src="../assets/img/mileidy.png" alt="Imagen 6">
  </div>
</div>





  </div>
</body>
</html>

   
  
    
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  margin: 0;
  padding: 0;
}

.background-image-1 {
  background-image: url('../assets/img/fondo-superior.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 600px;
  /* Ajusta el tamaño y posición de la imagen */
}

.background-image-2 {
   background-image: url('../assets/img/fondo-combi.png'); 
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 900px;
  /* Ajusta el tamaño y posición de la imagen */
}

</style>
